<template>
  <div>
    <v-container
      tile
      class="pa-0 fill-height"
      fluid
      :style="domainError ? 'height: 100vh; background-color: #212121' : 'height: 100vh; background-color: #ffffff'"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          xs="12"
          sm="8"
          md="6"
          lg="4"
          align="center"
        >
          <v-card
            class="pa-5"
            elevation="0"
            :color="domainError ? 'secondary' : 'white'"
          >
            <v-row
              no-gutters
              justify="center"
            >
              <v-img
                v-if="domainError"
                max-width="149px"
                src="static/Stacked - No Background-WhiteText.png"
                class="mb-8"
              />
              <v-progress-circular
                v-else     
                color="grey lighten-2"
                indeterminate
                size="130"
                width="10"
              ></v-progress-circular>
            </v-row>
            <v-row
              no-gutters
              v-if="domainError === 'nodomain'"
              class="grey--text mt-6 caption"
              justify="center"
            >
              Congratulations, your domain name is successfully directed to Incentable.
              Please ensure that you have provided your domain name to us so we can complete the configuration. 
              Allow up to 48 hours for processing and DNS propagation. Until processing is complete,
              you can access your program website at the temporary URL shown on your program dashboard
              in the Incentable Admin.
              <a href="https://app.incentable.com" target="_blank" class="sub-head-auth-page link mt-6">Contact support</a>
            </v-row>
            <v-row
              no-gutters
              v-if="domainError === 'noprogram'"
              class="grey--text mt-6 caption"
            >
              <v-col>
                <v-row no-gutters justify="center">
                  Congratulations, your domain name is successfully directed to Incentable.
                  However, there is no program linked to this URL.
                  <a href="https://app.incentable.com" target="_blank" class="sub-head-auth-page link mt-6">Contact support</a>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>

<script>
export default {
  computed: {
    domainError () {
      return this.$store.getters.domainError
    },
  },
}
</script>